<template>
  <AutoCompleteField
    v-model="innerValue"
    :items="items"
    :item-value="innerType"
    :rules="rules"
    v-bind="$attrs"
    :filter-items="(item) => item"
    @select="emitSelect($event)">
    <template #item-text="{ item }">
      <span
        v-for="(text, index) in textTemplate"
        :key="`${text}-${index}`"
        :class="{'font-bold': text === innerType}">
        <span>
          {{ text === '>' ? text : item[text] }}
        </span>
      </span>
    </template>
  </AutoCompleteField>
</template>

<script>
import { searchAddressByDistrict, searchAddressByAmphoe, searchAddressByProvince, searchAddressByZipcode } from 'thai-address-database'
import AutoCompleteField from './AutoCompleteField.vue'

export default {
  components: {
    AutoCompleteField
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true,
      validator: (type) => [
        'sub-district',
        'district',
        'province',
        'postcode'
      ].some((t) => t === type)
    },
    rules: {
      type: [String, Object],
      default: null
    }
  },
  data () {
    return {
      textTemplate: [
        'district',
        '>',
        'amphoe',
        '>',
        'province',
        '>',
        'zipcode'
      ]
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    innerType () {
      switch (this.type) {
      case 'sub-district': return 'district'
      case 'district': return 'amphoe'
      case 'province': return 'province'
      case 'postcode': return 'zipcode'
      default: return null
      }
    },
    items () {
      switch (this.innerType) {
      case 'district': return searchAddressByDistrict(this.innerValue)
      case 'amphoe': return searchAddressByAmphoe(this.innerValue)
      case 'province': return searchAddressByProvince(this.innerValue)
      case 'zipcode': return searchAddressByZipcode(this.innerValue)
      default: return []
      }
    }
  },
  methods: {
    emitSelect (item) {
      this.$emit('select', {
        subDistrict: item?.district || '',
        district: item?.amphoe || '',
        province: item?.province || '',
        postcode: item?.zipcode || ''
      })
    }
  }
}
</script>

<style scoped>

</style>
