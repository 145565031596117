<template>
  <b-modal
    id="modal-danger"
    v-model="modalShow"
    ok-variant="danger"
    ok-title="Accept"
    modal-class="modal-normal"
    centered>
    <div class="d-block text-center mb-1">
      <p class="font-weight-bold h2">
        {{ title }}
      </p>
    </div>
    <div
      class="mx-auto mb-2"
      style="width: 80%;">
      <b-row
        v-for="item, index in itemsKey"
        :key="`add-item-${index}`">
        <b-col
          cols="6"
          class="d-flex justify-content-end font-weight-bold">
          <span>{{ item }}</span>
        </b-col>
        <b-col cols="6">
          <span>{{ itemsValue[index] }}</span>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer="{ hide }">
      <div class="mx-auto">
        <b-button
          variant="primary"
          style="margin-right: 12px"
          @click="confirmAdd()">
          Confirm
        </b-button>
        <b-button
          variant="outline-primary"
          @click="hide">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    items: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: 'Modal'
    }
  },
  computed: {
    modalShow: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    itemsKey () {
      return Object.keys(this.items)
    },
    itemsValue () {
      return Object.values(this.items)
    }
  },
  methods: {
    confirmAdd () {
      this.$emit('input', false)
      this.$emit('confirm', this.items)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
