<template>
  <ValidationProvider
    #default="validationContext"
    :ref="refName"
    v-click-outside="onClickedOutside"
    :name="name"
    :vid="name"
    :rules="rules"
    tag="div">
    <b-form-group class="position-relative">
      <template #label>
        <label>
          {{ label }}
          <span
            v-if="isRequired"
            class="label-required">
            *
          </span>
        </label>
      </template>
      <b-form-input
        ref="input"
        v-model="innerValue"
        v-bind="$attrs"
        :class="innerClass"
        :state="$getValidationState(validationContext)"
        :required="isRequired"
        autocomplete="disabled"
        type="text"
        v-on="{ ...$listeners }"
        @focus="setHasFocus(true)"
        @blur="setHasFocus(false)"
        @keydown.up="pressArrow('up')"
        @keydown.down="pressArrow('down')"
        @keydown.enter="pressEnter()"
        @click="setOpenListContainer(true)" />
      <div
        v-if="resultsFromSearch.length && isOpenListContainer"
        ref="dropdown"
        class="autocomplete-list-container">
        <ul class="autocomplete-list">
          <li
            v-for="(item, index) in resultsFromSearch"
            :key="`list-item-${index}`"
            :class="[
              'autocomplete-item',
              {
                'autocomplete-item-focused': itemOnFocus === index
              }
            ]"
            @mouseover="setItemOnFocus(index)"
            @mouseout="setItemOnFocus(-1)"
            @click="clickSelectItem(item)">
            <slot
              name="item-text"
              v-bind="{ item }">
              {{ item }}
            </slot>
          </li>
        </ul>
      </div>
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'FormTextField',
  props: {
    rules: {
      type: [String, Object],
      default: null
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    filterItems: {
      type: Function,
      default: (items, value) => {
        const upperValue = value.toUpperCase()
        return items.filter((str) => str.toUpperCase().indexOf(upperValue) >= 0)
      }
    },
    itemValue: {
      type: String,
      default: null
    },
    innerClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      hasFocus: false,
      itemOnFocus: 0,
      isOpenListContainer: false
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        if (this.hasFocus) {
          this.setOpenListContainer(true)
        }
        this.itemOnFocus = 0
      }
    },
    refName () {
      return `validation-provider-${this.name}`
    },
    isRequired () {
      return this.rules === 'required' || !!this.rules?.required
    },
    resultsFromSearch () {
      return this.filterItems(this.items, this.innerValue)
    }
  },
  methods: {
    setHasFocus (data = false) {
      this.hasFocus = !!data
    },
    setItemOnFocus (data = 0) {
      this.itemOnFocus = data
    },
    setOpenListContainer (data = false) {
      this.isOpenListContainer = !!data
    },
    onClickedOutside () {
      this.setOpenListContainer()
    },
    pressArrow (direction) {
      if (direction === 'up') {
        this.itemOnFocus = this.itemOnFocus > 0 ? this.itemOnFocus - 1 : 0
      } else {
        this.itemOnFocus = this.itemOnFocus < this.resultsFromSearch.length - 1 ? this.itemOnFocus + 1 : this.resultsFromSearch.length - 1
      }
      this.moveScrollOfListContainer()
    },
    pressEnter () {
      this.setSelectedValue(this.resultsFromSearch[this.itemOnFocus])
    },
    moveScrollOfListContainer () {
      const list = this.$refs.dropdown
      const element = list.querySelectorAll('.autocomplete-item')[this.itemOnFocus]
      if (!element) return
      const visMin = list.scrollTop
      const visMax = list.scrollTop + list.clientHeight - element.clientHeight
      if (element.offsetTop < visMin) {
        list.scrollTop = element.offsetTop
      } else if (element.offsetTop >= visMax) {
        list.scrollTop = element.offsetTop - list.clientHeight + element.clientHeight
      }
    },
    clickSelectItem (item) {
      this.setSelectedValue(item)
    },
    setSelectedValue (item) {
      this.$emit('select', item)
      if (this.itemValue && typeof item === 'object') {
        this.innerValue = item[this.itemValue]
      } else {
        this.innerValue = item
      }
      this.$nextTick(() => { this.setOpenListContainer() })
    }
  }
}
</script>

<style scoped>
.autocomplete-list-container {
  z-index: 2;
  padding: 0;
  margin: 0;
  text-align: left;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  transition: all .25s;
}
ul.autocomplete-list {
  list-style: none;
  padding: 0;
}
li.autocomplete-item {
  padding: 5px 10px;
  color: #000000;
  background-color: #ffffff;
}
.autocomplete-item.autocomplete-item-focused {
  background-color: #e7e7e7;
}
</style>
