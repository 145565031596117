<template>
  <div>
    <ValidationProvider
      #default="validationContext"
      :ref="refName"
      :name="name"
      :vid="name"
      :rules="rules"
      tag="div">
      <b-form-group>
        <template #label>
          <div>
            {{ label }}
            <feather-icon
              v-if="isCanCall"
              variant="primary"
              icon="PhoneIcon"
              size="20"
              class="cursor-pointer text-primary"
              @click="isModal = !isModal"
            />
            <span
              v-if="isRequired"
              class="label-required">
              *
            </span>
          </div>
        </template>
        <b-form-input
          v-model="innerValue"
          :class="innerClass"
          v-bind="$attrs"
          :state="$getValidationState(validationContext)"
          v-on="{ ...$listeners }" />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <ClickToCallModal
      v-model="isModal"
      :phone-number="value" />
  </div>
</template>

<script>
import ClickToCallModal from '../Modal/ClickToCallModal.vue'

export default {
  components: {
    ClickToCallModal
  },
  props: {
    isCanCall: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, null],
      default: ''
    },
    name: {
      type: [String, null],
      default: ''
    },
    rules: {
      type: [String, Object],
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    },
    innerClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isModal: false

    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    refName () {
      return `validation-provider-${this.name}`
    },
    isRequired () {
      return this.rules === 'required' || !!this.rules?.required
    }
  }
}
</script>

<style>
</style>
